/* You can add global styles to this file, and also import other style files */

// @import "./assets/tailwind/tailwind.min.css";
@import "./assets/font-awesome/css/font-awesome.min.css";
@import "../node_modules/inspinia/dist/inspinia.css";
@import "../node_modules/inspinia/dist/fonts.css";
@import "./assets/css/animate.css";
@import "./assets/css/bootstrap.min.css";
@import "./assets/flexcal/flexcal.css";
@import "../node_modules/bootstrap-rtl/dist/css/bootstrap-rtl.min.css";
@import "./assets/js/plugins/jquery-ui/jquery-ui.min.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
// @import "./assets/css/highcharts.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/material-design-icons/iconfont/material-icons.css";
@import "./assets/css/style.css";
@import "./assets/Site.css";
.body,
.wrapper {
  /* Break the flow */
  position: absolute;
  top: 0px;

  /* Give them all the available space */
  width: 100%;
  height: 100%;

  /* Remove the margins if any */
  margin: 0;

  /* Allow them to scroll down the document */
  overflow-y: hidden;
}

.body {
  /* Sending body at the bottom of the stack */
  z-index: 1;
  font-family: "open sans", "Rubik", Arial, sans-serif !important;
  font-size: 16px !important;
}

.wrapper {
  /* Making the wrapper stack above the body */
  z-index: 2;
}
::ng-deep .modal-content {
  width: 800px;
}

.mat-ripple.mat-tab-label {
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  opacity: unset !important;
  border-bottom: 2px solid rgb(179 181 183) !important;
}

.mat-tab-header {
  border-bottom: none;
  padding-top: 10px !important;
}

.mat-ink-bar {
  background-color: white !important;
  color: black;
}
.mat-tab-label-active,
.mat-ripple-element,
.cdk-mouse-focused {
  background-color: white !important;
  font-weight: 600 !important;
  font-size: revert !important;
}

.mat-tab-label-active,
.mat-ripple-element {
  border: 2px solid rgb(179 181 183) !important;
  border-bottom: none !important;
}

.mat-tab-body {
  padding-top: 4px;
  background-color: WHITE;
}

.text-danger {
  display: block;
  color: red;
  font-size: small;
}
.circles {
  padding: 25px;
  display: flex;
  justify-content: center;
  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px;
    -webkit-animation: colorChange 1s linear infinite; /* Safari 4.0 - 8.0 */
    animation: colorChange 1s linear infinite; // cubic-bezier(0, 0.5, 0.5, 1)
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
    &:nth-child(6) {
      animation-delay: 0.1s;
    }
    &:nth-child(7) {
      animation-delay: 0.12s;
    }
    &:nth-child(8) {
      animation-delay: 0.14s;
    }
  }
}
.bar-circles {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px;
    -webkit-animation: colorChange 1s linear infinite; /* Safari 4.0 - 8.0 */
    animation: colorChange 1s linear infinite; // cubic-bezier(0, 0.5, 0.5, 1)
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
    &:nth-child(6) {
      animation-delay: 0.1s;
    }
    &:nth-child(7) {
      animation-delay: 0.12s;
    }
    &:nth-child(8) {
      animation-delay: 0.14s;
    }
  }
}

.primary {
  background-color: #770400;
}

.alternate {
  background-color: black;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes colorChange {
  // from {background-color: #770400;}
  to {
    background-color: transparent;
  }
}

@keyframes colorChange {
  // from {background-color: #770400;}
  to {
    background-color: transparent;
  }
}

.mat-header-row,
.mat-row,
.mat-header-cell {
  background-color: #f9f9ff;
  // background-color: #888f9630;
  min-height: 30px;
  padding: 4px !important;
  border-top: 0.5px solid #8c8e91;
  border-bottom: solid 2px !important;
  color: rgb(0 0 0 / 77%);
}

.mat-header-row > .mat-header-cell {
  background-color: #f3f5f6 !important;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.loading i {
  color: white;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mat-paginator {
  display: block;
  padding-top: 5px;
  border-top: solid 0.5px;
  padding-bottom: 5px;
  border-bottom: solid 1px !important;
}

.mat-paginator,
.mat-paginator > .mat-paginator-outer-container {
  max-height: 40px !important;
  overflow-y: hidden;
  background: #f3f5f6;
}
.mat-paginator-page-size {
  // .mat-form-field-label-wrapper,
  .mat-paginator-range-actions,
  // .mat-form-field-flex,
  .mat-paginator-page-size-select,
  .mat-select-arrow,
  // .mat-form-field-infix ,
  .mat-select-arrow-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
  .mat-form-field-wrapper {
    //padding-bottom: 0 !important;
    height: auto !important;
  }
}

::ng-deep .mat-header-row {
  min-height: 48px;
  box-shadow: inset -1px -4px 0px 0px rgb(255, 255, 255), inset 0px -2px 0px 0 rgb(255, 255, 255),
    inset -5px -1px 18px 0 rgb(255, 255, 255);
  background-color: #f5f5f500 !important;
}
::ng-deep .mat-input-element {
  line-height: 18px !important;
}
::ng-deep .mat-form-field-flex {
  line-height: 22px !important;
}
::ng-deep .mat-form-field-infix {
  border: 2px !important;
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}

::ng-deep .mat-form-field-underline {
  bottom: 0 !important;
}
::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  height: 48px !important;
}
::ng-deep .mat-form-field {
  padding-bottom: 12px !important;
}
::ng-deep .mat-form-field-label.mat-form-field-empty {
  margin: 0 !important;
}
::ng-deep .mat-header-cell {
  vertical-align: top !important;
}

::ng-deep .mat-cell {
  padding: 2px 10px !important;
}
::ng-deep .mat-table {
  padding-top: 10px !important;
}

.mini {
  height: 34px !important;
  width: 34px !important;
  margin: 0 auto;
  vertical-align: middle;
}

::ng-deep button.mini > .mat-button-wrapper {
  padding: 0 !important;
}
