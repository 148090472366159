.ul-header {
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 10px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  border: solid rgb(231, 234, 236);
  border-width: 1px 6px;
  margin-top: 20px;
}

.scrolling-wrapper {
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow: visible;
}
.header-h1 {
  padding: 0;
  margin: 0 auto;
  vertical-align: bottom;
  line-height: 32px;
  text-align: center;
  font-size: 30px !important;
  color: #377cb8;
  padding: 0px 20px 5px 20px;
  /* text-decoration: underline; */
  /* margin: 0 auto;
  padding: 0;
  line-height: 32px;
  text-align: center;
  font-size: 30px;
  color: #377cb8;
  background-color: #d8dde2;
  padding: 0px 20px 5px 20px;
  border-radius: 0px 0px 5px 5px;
  border-width: 0px 2px;
  border: solid 2px;
  border-top-width: 0;
  border-color: #19aa8d; */
}

fieldset {
  border: 1px solid #ddd !important;
  border-radius: 4px;
  padding: 10px;
  /* width: 100% !important; */
}

legend {
  padding: 10px 20px;
  margin: 0;
  border: none;
  width: auto;
}

.form-control.ng-valid:not(form),
.form-control.ng-valid.required {
  border-left: 2px solid #42a948;
  /* green */
}

.form-control.ng-invalid:not(form),
.form-control.ng-invalid.required {
  border-left: 2px solid red;
  /* red */
}

.input-group {
  width: min-content;
  display: block;
}

.form-control:not(:first-child):not(:last-child) {
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.mat-select-value {
  max-width: none !important;
}
.narrow {
  min-width: 84px !important;
  width: 84px !important;
  max-width: 84px !important;
}
th.narrow {
  min-width: 84px !important;
  width: 84px !important;
  max-width: 84px !important;
}
input.narrow {
  min-width: 84px !important;
  width: 84px !important;
  max-width: 84px !important;
}
.form-control {
  /* width: auto; */
  min-width: 158px;
  /* width: auto !important; */
  width: fit-content !important;
}
.form-control:focus,
.mat-raised-button:focus {
  border: 2px solid gray;
}

.small-input {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
  border-top-width: 0 !important;
  border-bottom-width: 1px !important;
  min-width: 158px;
}
.form-row {
  margin-bottom: 10px;
}
.form-group {
  margin-bottom: 0px;
}

.mat-card {
  padding: 5px !important;
  font-family: "open sans", "Rubik", Arial, sans-serif !important;
  font-size: 16px !important;
}
.mat-card-header {
  background-color: #e7eaec7d;
  padding: 8px 16px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.mat-card-content {
  padding: 16px !important;
  min-height: 70px;
}
.mat-card-header-text {
  width: 100% !important;
  white-space: nowrap !important;
  margin: 0 !important;
}
.mat-card-title {
  margin: 0 !important;
}

.mat-button-toggle > .mat-button-toggle-button > .mat-button-toggle-label-content {
  /* font-weight: bold !important; */
  /* text-decoration: underline !important;  */
  height: auto !important;
  line-height: 28px;
  margin: 0 auto;
  vertical-align: middle;
}

.mat-button-toggle {
  font-size: 14px !important;
  color: #676a6c !important;
  line-height: inherit !important;
}

.mat-button-toggle-checked {
  font-weight: bold !important;
  text-decoration: underline !important;
  background-color: #e0e0e0a1;
  border: solid 1px;
}

.delete {
  color: red;
}

.done {
  color: green;
}

.edit {
  color: yellow;
}

header {
  background-color: #333;
  color: #fff;
  overflow: auto;
  position: sticky;
  top: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

mat-icon {
  cursor: pointer;
}

.action-container {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin: 6px;
}

.table {
  white-space: nowrap;
  /* width: min-content; */
  padding: 10px;
  margin-bottom: 6px;
  font-size: 14px;
  border: 1px solid #e7eaec;
}

.table tr td,
.table thead th,
.table thead {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  /* padding: 1px !important; */
  vertical-align: middle;
}

.table td input,
.table thead th {
  padding: 3px 6px !important;
}
.output {
  padding: 20px;
}

/* table, th , td  {
      border: 1px solid grey;
      border-collapse: collapse;
      padding: 5px;
    } */
table tr:nth-child(odd) {
  background-color: #f1f1f1;
}
table tr:nth-child(even) {
  background-color: #ffffff;
}

.example-fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout.
       נותן רווח מלא לדחיפה ימין שמאל */
  flex: 1 1 auto;
}

/* Set padding to keep content from hitting the edges */

.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Set width on the form input elements since they're 100% wide by default */

/* input,
select,
textarea {
    max-width: 280px;
}*/

/* styles for validation helpers */

.field-validation-error {
  color: #b94a48;
}

.field-validation-valid {
  display: none;
}

input.input-validation-error {
  border: 1px solid #b94a48;
}

input[type="checkbox"].input-validation-error {
  border: 0 none;
}

.validation-summary-errors {
  color: #b94a48;
}

.validation-summary-valid {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-horizontal .control-label {
  text-align: right !important;
}

.dl-horizontal dt {
  text-align: right !important;
  width: inherit !important;
  font-weight: 600;
}

.dl-horizontal dt::after {
  content: ":";
}

.dl-horizontal .f_content {
  border-bottom: 1px solid #eee !important;
  width: 100%;
  padding: 8px 0;
}

.dl-horizontal .f_name {
  text-align: right !important;
  width: inherit !important;
  font-weight: 600;
}

.dl-horizontal .f_name::after {
  content: ":";
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0 !important;
  border-top: 1px solid #eee !important;
  width: 100%;
}

.ui-datepicker {
  z-index: 99999 !important;
}

.ui-datepicker-header {
  text-align: center;
}

ul.dropdown-menu {
  z-index: 9999;
}

div[routerModal].modal .modal-body {
  max-height: 620px;
  overflow-y: auto;
  /* background-color: #f3f3f4; */
}

.btn {
  margin: 3px !important;
}

textarea.comment {
  background-image: -webkit-linear-gradient(top, transparent, transparent 24px, #e7eff8 0);
  background-size: 100% 25px;
  background-color: LIGHTYELLOW;
  width: 100%;
  font-size: 17px;
  line-height: 25px;
}
dd.comment {
  background-image: -webkit-linear-gradient(top, transparent, transparent 24px, #e7eff8 0);
  background-size: 100% 25px;
  background-color: LIGHTYELLOW;
  /* width: 100%; */
  font-size: 17px;
  line-height: 25px;
}
textarea.comment-white {
  background-image: -webkit-linear-gradient(top, transparent, transparent 24px, #e7eff8 0);
  background-size: 100% 25px;
  background-color: white;
  width: 100%;
  font-size: 17px;
  line-height: 25px;
}

.table > thead > tr > th .table > tfoot > tr > td {
  padding: 8px 4px;
  vertical-align: middle;
}

.pagination > .active > span:hover {
  background-color: #ddd;
}

.example-card {
  border-radius: 0 !important ;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 1px rgb(0 0 0 / 12%) !important;
}

.mat-card-header-text > .mat-card-title > h3 {
  align-self: center !important ;
  margin: 5px auto !important ;
}

.example-container {
  /* width: 100%; */
  height: 100%;
  /* position: relative;
  display: flex;
  flex-direction: column; */
  min-width: 300px;
  /* height: 300px; */
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
}

.example-sidenav-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.example-sidenav {
  padding: 20px;
}

/* DivTable.com */

.divTable {
  display: table;
  width: 100%;
  margin-bottom: 12px;
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.divTableRow {
  display: table-row;
  border-width: 2px 0px;
  background-color: whitesmoke;
  padding: 4px 0;
  border: solid;
  border-right-width: 0;
  border-left-width: 0;
  border-color: white;
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableHeading > .divTableCell {
  padding: 10px 4px 10px 0px;
}
.divTableCel > select.form-control,
.divTableCel > mat-select.form-control,
.divTableCel > input.form-control {
  /* width: -webkit-fill-available !IMPORTANT; */
  width: 100% !important;
}
.divTableCell,
.divTableHeading {
  /* border: 1px solid #999999; */
  display: table-cell;
  vertical-align: middle;

  /* padding: 3px 10px; */
}

.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
  flex-direction: inherit;
}

.legend-title {
  display: none;
}

.ngx-charts,
.ngx-charts-outer,
.ngx-charts-outer > div,
.ngx-charts-outer > div > .ngx-charts {
  width: 100% !important;
  height: 300px !important;
  max-height: 350px !important;
  overflow: visible !important;
}

svg:not(:root) {
  overflow: visible !important;
}

ngx-charts-legend > div {
  position: absolute;
}

/* .example-month-picker .mat-calendar-period-button {
                    pointer-events: none;
                  }

                  .example-month-picker .mat-calendar-arrow {
                    display: none;
                  } */

/* Structure */

/* .example-container {
  position: relative;
} */

.example-table-container {
  position: relative;
  max-height: 400px;
  overflow: auto;
}

table {
  width: 100%;
}

.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.example-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/*
  mat-row:nth-child(even){
    background-color:#ffffff!important;
                }

  tr.mat-row:nth-child(odd){
    background-color:#e7eaec3b !important;
                 }


                 .mat-sort-header-container{
                    font-size: 14px;
                    font-weight: bold;
                 }

                 .mat-header-cell {
                  font-size: 14px !important;
                 font-weight: bold !important;
                } */

.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-toolbar.mat-accent {
  background-color: #e3e3e3 !important;
  color: rgba(0, 0, 0, 0.87);

  /* background-color: royalblue !important; */
}

.hovered {
  background-color: goldenrod;
}

.mat-header-cell {
  font-size: 15px !important;
  font-weight: bold;
}

.mat-table tr.example-detail-row {
  height: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.mat-table .mat-row:not(.alternate):nth-child(even) {
  background: #f9f9ff;
  /* background: #888f9630; */
}
.mat-table .mat-row:nth-child(odd) {
  background: #fff !important;
}

/*
// #324c6530 !important;

.mat-table .mat-row.alternate:nth-child(odd) {
  background: #green !important;
} */

mat-footer-row,
mat-row {
  white-space: nowrap;
  height: 28px;
  max-height: 28px !important;
  min-height: 22px !important;
  padding-left: 2px;
  padding-right: 2px;
}

[dir="rtl"] mat-cell:last-of-type,
[dir="rtl"] mat-footer-cell:last-of-type,
[dir="rtl"] mat-header-cell:last-of-type,
.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-left: 2px !important;
  padding-right: 2px !important;
  /* max-height:22px !important; */
  min-height: 22px !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.mat-option.active {
  background-color: #324c6529 !important;
  font-weight: bold;
}
.mat-option {
  height: 40px !important;
  line-height: 40px !important;
  /* border-bottom: 0.4px solid rgba(0, 0, 0, 0.42); */
  padding: 0 8px !important;
}

.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background-color: #324c6529 !important;
  font-weight: bold;
}

.mat-dialog-content {
  margin: 0px !important;
}

.mat-dialog-actions {
  margin-bottom: 0px !important;
}

.ibox-title > h5 {
  font-size: 20px;
  padding-bottom: 3px;
}

.ibox-title {
  padding: 10px 10px 5px 5px;
}

.example-full-width {
  width: 100%;
}

.div-list:nth-child(odd) {
  background: white;
}

.div-list:nth-child(even) {
  background: rgb(221, 221, 221);
}

/* .mat-form-field-infix {
  padding: 3px;
} */

/* Structure */

/* .example-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
} */

.example-header {
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 20px;
}

.bounds > .content > .mat-table {
  width: 100%;
}

.mat-table {
  font-family: "open sans", "Rubik", Arial, sans-serif;
  font-size: 16px !important;
  display: table;
  /* width: 100%; */
  overflow: auto;
}

/* .mat-column-name{
  border-right: 1px solid grey;
  align-self: stretch;
  text-align: center


  }
  .mat-column-position{
    border-right: 1px solid grey;
    align-self: stretch;
    text-align: center;

    }
    .mat-column-weight{
    border-right: 1px solid grey;
    align-self: stretch;
    text-align: center;

    }
    .mat-column-symbol{

    text-align: center;
    align-self: stretch;
    }
   .mat-column-weight{
       align-self: stretch;
    }  */
.mat-table > .mat-header-row > .mat-header-cell {
  font-weight: 600 !important;
  white-space: nowrap;
}

.mat-table > .mat-header-row,
.mat-table > .mat-row {
  display: table-row;
  padding: 0;
  border: none;
}

.mat-table > .mat-header-row > .mat-header-cell,
.mat-table > .mat-row > .mat-cell {
  display: table-cell;
  vertical-align: middle;
  border-bottom: 0.5px solid rgb(179 181 183);
  border-left: 0.01px solid rgb(179 181 183 / 13%);
  padding: 0.5px 2px;
}

.mat-table > .mat-row > .mat-cell > button {
  height: auto !important;
  line-height: inherit;
}

.example-list {
  /* width: 500px; */
  max-width: 100%;
  border: solid 1px #ccc;
  /* min-height: 60px; */
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 10px 5px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  /* font-size: 14px; */
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.header-label {
  width: fit-content;
  font-style: italic;
  text-decoration: underline;
  background-color: #dadada66;
}

.fieldset {
  border-radius: 0px;
  padding: 0;
  margin: 0;
}

.fieldset > legend {
  background-color: white;
  margin-right: 16px;
  border-bottom: solid #ccc9c9;
  padding: 5px 15px;
  font-size: large;
  font-weight: inherit;
}

@media screen and (max-width: 768px) {
  .mat-header-cell,
  .mat-cell {
    max-width: 25% !important;
    height: 44px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .hidden-print {
    visibility: hidden;
  }
  .visible-print {
    visibility: visible;
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 5px auto;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #243747;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
